<template>
  <v-card flat outlined class="pa-4">
    <v-row>
      <v-col cols="12">
        <v-checkbox
          color="primary"
          hide-details
          dense
          v-model="allChecked"
          :indeterminate="indeterminate"
          @change="toggleAll"
          :readonly="readonly"
        >
          <template v-slot:label>
            <v-sheet class="font-weight-medium" color="transparent">
              {{ module.name }}
            </v-sheet>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        class="pt-0"
        v-for="(permission, i) in module.permissions"
        :key="permission.id"
      >
        <v-checkbox
          :label="permission.name"
          color="primary"
          hide-details
          dense
          v-model="childCheckboxes[i].value"
          :readonly="readonly"
        >
        </v-checkbox>
      </v-col>
    </v-row>
  </v-card>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    indeterminate() {
      const checkedCount = this.childCheckboxes.filter((c) => c.value).length;
      return checkedCount > 0 && checkedCount < this.childCheckboxes.length;
    },
  }),
  watch: {
    childCheckboxes: {
      handler(newVal) {
        const allChecked = newVal.every((c) => c.value);
        if (this.allChecked !== allChecked) {
          this.allChecked = allChecked;
        }
        this.updatePermissions();
      },
      deep: true,
    },
    allChecked(newVal) {
      if (newVal) {
        this.childCheckboxes = this.childCheckboxes.map((c) => ({
          ...c,
          value: true,
        }));
      }
    },
  },
  props: ["rolePermissions", "module", "readonly"],
  data: () => ({
    allChecked: false,
    childCheckboxes: [],
  }),
  created() {
    if (this.module.permissions) {
      this.childCheckboxes = this.module.permissions.map((permission) => ({
        id: permission.id,
        value:
          this.rolePermissions && this.rolePermissions.includes(permission.id)
            ? true
            : false, 
      }));
    }
  },
  mounted() {
    //
  },
  methods: {
    toggleAll() {
      this.childCheckboxes = this.childCheckboxes.map((c) => ({
        ...c,
        value: this.allChecked,
      }));
    },
    updatePermissions() {
      this.$emit("updatePermissions", this.childCheckboxes);
    },
  },
};
</script>